import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "to", "hyperlinkType", "as", "className", "styleType", "type", "noLink", "ariaLabel", "inheritedStyles", "handleLinkClick", "forceClick", "title", "ctaBehavior", "icon", "iconPosition"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import CustomIcon from '../CustomIcon';
import { buildUrl } from '../../../utils/url';
import { createLinkID, getTargetWindow } from '../../../utils/utilityFunction';
import { linkTargetWindow } from '../../../constants/globalConstants';
import styles from './Anchor.style';
import { replaceSpaceInUrl } from '../../../utils';
var typeMap = {
  Primary: 'primary-anchor',
  Secondary: 'secondary-anchor',
  Tertiary: 'tertiary-anchor'
};
var Anchor = function Anchor(_ref) {
  var children = _ref.children,
    to = _ref.to,
    hyperlinkType = _ref.hyperlinkType,
    as = _ref.as,
    className = _ref.className,
    styleType = _ref.styleType,
    type = _ref.type,
    noLink = _ref.noLink,
    ariaLabel = _ref.ariaLabel,
    inheritedStyles = _ref.inheritedStyles,
    handleLinkClick = _ref.handleLinkClick,
    forceClick = _ref.forceClick,
    title = _ref.title,
    ctaBehavior = _ref.ctaBehavior,
    icon = _ref.icon,
    iconPosition = _ref.iconPosition,
    others = _objectWithoutProperties(_ref, _excluded);
  var updatedStyleType = typeMap[type] || styleType;
  var parsedUrl = replaceSpaceInUrl(to);
  var href = parsedUrl;
  var target = getTargetWindow(ctaBehavior);
  var rel = ctaBehavior === linkTargetWindow.newWindow ? 'noopener noreferrer' : undefined;
  if (noLink && parsedUrl) {
    href = buildUrl(parsedUrl);
  } else if (forceClick && parsedUrl) {
    href = parsedUrl;
  } else if (hyperlinkType && parsedUrl) {
    if (hyperlinkType === 'Phone') {
      href = "tel:".concat(parsedUrl);
      rel = undefined;
    } else if (hyperlinkType === 'SMS') {
      href = "sms:".concat(parsedUrl);
      rel = undefined;
    } else if (hyperlinkType === 'Email') {
      href = "mailto:".concat(parsedUrl);
      rel = undefined;
    }
  }
  var handleTouchEnd = function handleTouchEnd(e) {
    if (href && e && e.currentTarget) {
      e.currentTarget.href = href;
    }
  };
  return __jsx("a", _extends({
    title: title,
    role: href ? 'link' : 'button',
    href: href,
    className: cn('nva-anchor', updatedStyleType === '' ? '' : "nva-anchor--".concat(updatedStyleType, " sg-btn-cpnt ").concat(updatedStyleType), updatedStyleType === 'primary-anchor' || updatedStyleType === 'secondary-anchor' || updatedStyleType === 'tertiary-anchor' || updatedStyleType === 'hero-button' ? 'nva-anchor--button' : '', className),
    onClick: handleLinkClick || handleTouchEnd,
    target: target,
    rel: rel
  }, others), icon && iconPosition === 'Left' && __jsx(CustomIcon, {
    icon: icon
  }), children, icon && iconPosition === 'Right' && __jsx(CustomIcon, {
    icon: icon
  }));
};
Anchor.defaultProps = {
  noLink: false,
  as: '',
  hyperlinkType: 'URL',
  forceClick: false,
  styleType: '',
  ctaBehavior: ''
};
var FakeAnchor = function FakeAnchor(props) {
  return __jsx("span", _extends({}, props, {
    className: "fakeAnchor ".concat(props.className)
  }), props.children);
};
export var FakeAnchorStyled = styled(FakeAnchor).withConfig({
  componentId: "sc-1ux5mjm-0"
})(["", ""], styles);
export default styled(Anchor).withConfig({
  componentId: "sc-1ux5mjm-1"
})(["", ";"], styles);
export { Anchor as AnchorVanilla };